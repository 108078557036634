<template>
  <v-sheet v-bind="propsCompSheet" data-cy="documents-shares-create">
    <template v-if="documentId">
      <div class="mb-4">
        <v-form :disabled="formDisabled" @submit.prevent>
          <div class="formField">
            <v-row>
              <!-- Valid From Date & Time -->
              <v-col cols="6">
                <!-- Valid From Date -->
                <v-menu
                  ref="menuValidFromDate"
                  v-model="menuValidFromDate"
                  :close-on-content-click="false"
                  :return-value.sync="editValidFromDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  data-cy="menu-data-inici"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-bind="propsFormFields"
                      :label="$t('documentsShares.validFromDate')"
                      v-model="editValidFromDate"
                      :error-messages="editValidFromDateErrors"
                      @input="$v.editValidFromDate.$touch()"
                      @blur="
                        $v.editValidFromDate.$touch();
                        $v.editValidFromTime.$touch();
                      "
                      @click:append="menuValidFromDate = !menuValidFromDate"
                      v-on="on"
                      readonly
                      clearable
                      append-icon="$datePicker"
                      data-cy="input-data-inici"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="pickerValidFromDate"
                    v-bind="propsFormDatePicker"
                    v-model="editValidFromDate"
                    first-day-of-week="1"
                    :locale="$i18n.locale"
                  >
                    <v-btn
                      v-bind="propsButtonAction"
                      @click="menuValidFromDate = false"
                      >{{ $t("common.cancel") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      v-bind="propsButtonAction"
                      @click="$refs.menuValidFromDate.save(editValidFromDate)"
                      >{{ $t("common.save") }}</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <!-- Valid From Time -->
                <v-menu
                  ref="menuValidFromTime"
                  v-model="menuValidFromTime"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  :return-value.sync="editValidFromTime"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-bind="propsFormFields"
                      :label="$t('documentsShares.validFromTime')"
                      v-model="editValidFromTime"
                      :error-messages="editValidFromTimeErrors"
                      @input="$v.editValidFromTime.$touch()"
                      @blur="
                        $v.editValidFromDate.$touch();
                        $v.editValidFromTime.$touch();
                      "
                      @click:append="menuValidFromTime = !menuValidFromTime"
                      v-on="on"
                      readonly
                      clearable
                      append-icon="$timePicker"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-bind="propsTimePicker"
                    v-model="editValidFromTime"
                    :locale="$i18n.locale"
                  >
                    <v-btn
                      v-bind="propsButtonAction"
                      @click="menuValidFromTime = false"
                      >{{ $t("common.cancel") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      v-bind="propsButtonAction"
                      @click="$refs.menuValidFromTime.save(editValidFromTime)"
                      >{{ $t("common.save") }}</v-btn
                    >
                  </v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>

          <div class="formField">
            <v-row>
              <!-- Valid To Date & Time -->
              <v-col cols="6">
                <!-- Valid To Date -->
                <v-menu
                  ref="menuValidToDate"
                  v-model="menuValidToDate"
                  :close-on-content-click="false"
                  :return-value.sync="editValidToDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  data-cy="menu-data-inici"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-bind="propsFormFields"
                      :label="$t('documentsShares.validToDate')"
                      v-model="editValidToDate"
                      :error-messages="editValidToDateErrors"
                      @input="$v.editValidToDate.$touch()"
                      @blur="
                        $v.editValidToDate.$touch();
                        $v.editValidToTime.$touch();
                      "
                      @click:append="menuValidToDate = !menuValidToDate"
                      v-on="on"
                      readonly
                      clearable
                      append-icon="$datePicker"
                      data-cy="input-data-inici"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="pickerValidToDate"
                    v-bind="propsFormDatePicker"
                    v-model="editValidToDate"
                    first-day-of-week="1"
                    :locale="$i18n.locale"
                  >
                    <v-btn
                      v-bind="propsButtonAction"
                      @click="menuValidToDate = false"
                      >{{ $t("common.cancel") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      v-bind="propsButtonAction"
                      @click="$refs.menuValidToDate.save(editValidToDate)"
                      >{{ $t("common.save") }}</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <!-- Valid To Time -->
                <v-menu
                  ref="menuValidToTime"
                  v-model="menuValidToTime"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  :return-value.sync="editValidToTime"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-bind="propsFormFields"
                      :label="$t('documentsShares.validToTime')"
                      v-model="editValidToTime"
                      :error-messages="editValidToTimeErrors"
                      @input="$v.editValidToTime.$touch()"
                      @blur="
                        $v.editValidToDate.$touch();
                        $v.editValidToTime.$touch();
                      "
                      @click:append="menuValidToTime = !menuValidToTime"
                      v-on="on"
                      readonly
                      clearable
                      append-icon="$timePicker"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-bind="propsTimePicker"
                    v-model="editValidToTime"
                    :locale="$i18n.locale"
                  >
                    <v-btn
                      v-bind="propsButtonAction"
                      @click="menuValidToTime = false"
                      >{{ $t("common.cancel") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      v-bind="propsButtonAction"
                      @click="$refs.menuValidToTime.save(editValidToTime)"
                      >{{ $t("common.save") }}</v-btn
                    >
                  </v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>

          <div class="formField">
            <!-- <span class="reqMark">*</span> -->
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('common.email')"
              v-model="editEmail"
              :error-messages="editEmailErrors"
              @input="$v.editEmail.$touch()"
              @blur="$v.editEmail.$touch()"
              type="text"
            >
            </v-text-field>
          </div>
        </v-form>
      </div>
      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <v-btn
          v-bind="propsButtonAction"
          :loading="status.loading"
          :disabled="!formReadyForSubmit"
          @click="clickCreate"
          data-cy="button-create"
          >{{ $t("common.create") }}</v-btn
        >
      </div>
    </template>
    <v-alert v-else type="error">
      {{ $t("documents.error") }}
    </v-alert>
  </v-sheet>
</template>

<script>
import moment from "moment";
import { helpers, requiredIf } from "vuelidate/lib/validators";

import ComponentStatus from "@/modules/base/componentStatus.mixin";

const emailList = helpers.regex(
  "emailList",
  /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}(\s+[\w-.]+@([\w-]+\.)+[\w-]{2,4})*$/
);

const isDateSameOrAfter = (value, vm) => {
  if (
    vm.editTempValidToDateTime === null ||
    vm.editTempValidFromDateTime === null
  ) {
    return true;
  }
  if (vm.editTempValidToDateTime && vm.editTempValidFromDateTime) {
    return vm.editTempValidToDateTime.isSameOrAfter(
      vm.editTempValidFromDateTime
    );
  }
  return false;
};

export default {
  name: "DocumentsSharesCreate",
  mixins: [ComponentStatus],
  props: {
    documentId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    valid: false,

    menuValidFromDate: false,
    menuValidFromTime: false,
    menuValidToDate: false,
    menuValidToTime: false,

    editValidFromDate: null,
    editValidFromTime: null,
    editValidToDate: null,
    editValidToTime: null,

    editEmail: null,
  }),
  validations: {
    editValidFromDate: {
      isDateSameOrAfter,
      required: requiredIf("editValidFromTime"),
    },
    editValidFromTime: {
      isDateSameOrAfter,
      required: requiredIf("editValidFromDate"),
    },
    editValidToDate: {
      isDateSameOrAfter,
      required: requiredIf("editValidToTime"),
    },
    editValidToTime: {
      isDateSameOrAfter,
      required: requiredIf("editValidToDate"),
    },
    editEmail: {
      emailList,
    },
  },
  computed: {
    editTempValidFromDateTime() {
      if (this.editValidFromDate && this.editValidFromTime) {
        return moment(`${this.editValidFromDate} ${this.editValidFromTime}`);
      }
      return null;
    },
    validFromDateTime() {
      if (this.editTempValidFromDateTime) {
        return this.editTempValidFromDateTime.toDate();
      }
      return null;
    },
    editTempValidToDateTime() {
      if (this.editValidToDate && this.editValidToTime) {
        return moment(`${this.editValidToDate} ${this.editValidToTime}`);
      }
      return null;
    },
    validToDateTime() {
      if (this.editTempValidToDateTime) {
        return this.editTempValidToDateTime.toDate();
      }
      return null;
    },
    editUserId() {
      return this.$store.getters["auth/getUserId"];
    },
    formDisabled() {
      // if (this.isAdmin) {
      //   return false;
      // }
      return false;
    },
    formReadyForSubmit() {
      // if (this.status.loading) {
      //   return false;
      // }
      // if (this.status.readError) {
      //   return false;
      // }
      // if (this.isAdmin) {
      //   return true;
      // }
      // if (this.firstFormClick) {
      //   return true;
      // }
      if (this.$v.$invalid) {
        return false;
      }
      // if (!this.$v.$anyDirty) {
      //   return false;
      // }
      return true;
    },
    editValidFromDateErrors() {
      const errors = [];
      if (!this.$v.editValidFromDate.$dirty) return errors;
      if (!this.$v.editValidFromDate.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editValidFromDate.isDateSameOrAfter) {
        errors.push(this.$t("documentsShares.errors.isDateSameOrAfter"));
      }
      return errors;
    },
    editValidFromTimeErrors() {
      const errors = [];
      if (!this.$v.editValidFromTime.$dirty) return errors;
      if (!this.$v.editValidFromTime.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editValidFromTime.isDateSameOrAfter) {
        errors.push(this.$t("documentsShares.errors.isDateSameOrAfter"));
      }
      return errors;
    },
    editValidToDateErrors() {
      const errors = [];
      if (!this.$v.editValidToDate.$dirty) return errors;
      if (!this.$v.editValidToDate.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editValidToDate.isDateSameOrAfter) {
        errors.push(this.$t("documentsShares.errors.isDateSameOrAfter"));
      }
      return errors;
    },
    editValidToTimeErrors() {
      const errors = [];
      if (!this.$v.editValidToTime.$dirty) return errors;
      if (!this.$v.editValidToTime.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editValidToTime.isDateSameOrAfter) {
        errors.push(this.$t("documentsShares.errors.isDateSameOrAfter"));
      }
      return errors;
    },
    editEmailErrors() {
      const errors = [];
      if (!this.$v.editEmail.$dirty) return errors;
      if (!this.$v.editEmail.emailList) {
        errors.push(this.$t("common.errors.email"));
      }
      return errors;
    },
  },
  methods: {
    clickCreate() {
      this.setStatusCreating();
      let payload = {
        ownerId: this.editUserId,
        documentId: this.documentId,
        type: "public_document",
      };
      if (this.editTempValidFromDateTime) {
        payload.validFrom = this.editTempValidFromDateTime.toISOString();
      }
      if (this.editTempValidToDateTime) {
        payload.validTo = this.editTempValidToDateTime.toISOString();
      }
      if (this.editEmail) {
        payload.emails = this.editEmail;
      }
      this.$store
        .dispatch("documentsShares/create", payload)
        .then((result) => {
          // Removing all errors from server.
          this.$v.$touch();
          // Setting component status to success.
          this.setStatusCreateSuccess();
          this.$store.commit("status/showSuccess");
          if (result) {
            this.$router.push({
              name: "documents-public-show",
              params: {
                token: result,
              },
            });
          } else {
            this.$router.push({
              name: "documents-shares-index",
              params: {
                token: result,
              },
            });
          }
        })
        .catch((/* error */) => {
          // Setting the appropriate error markers from the server response.
          this.$v.$touch();
          // Setting component status to failure.
          this.setStatusCreateError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
